<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Invoice Number</th>
                        <th>Invoice Type</th>
                        <th>Client Name</th>
                        <th>Business Unit</th>
                        <th>Sales Person</th>
                        <th>Invoice Amount</th>
                        <th>VAT Amount</th>
                        <th>Total Amount</th>
                        <th>Discount Amount</th>
                        <th>Paid Amount</th>
                        <th>Due Amount</th>
                        <th>COGS</th>
                        <th>Gross Profit</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">
                        <td>{{ item.date }} </td>
                        <td>{{ item.bill_number }}</td>
                        <td>{{ item.invoice_type }}</td>
                        <td>{{ item.contact_name }}</td>
                        <td> </td>
                        <td>{{ item.sr_name }}</td>
                        <td> </td>
                        <td>{{ item.vat_amount }}</td>
                        <td>{{ item.total_amount }}</td>
                        <td>{{ item.discount }}</td>
                        <td> </td>
                        <td>{{ getTotalDue(item) }}</td>
                        <td> </td>
                        <td> </td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
const props = defineProps({
    tableItems: {
        type: Array,
    }
})

const getTotalDue = (item) => {
    return parseFloat(item.total_amount) - parseFloat(item.net_receipt_amount) - parseFloat(item.ait_deducted) - parseFloat(item.vat_deducted) - parseFloat(item.discount)
}
</script>

<style scoped>
.mr-5 {
    margin-right: 5px;
}
.action-th {
    width: 20%;
    text-align: center;
}
.name {
    width: 15%;
}
.action{
    text-align: center;
}

</style>