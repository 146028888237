<template>
  <div class="card p-2" style="min-height: calc(100vh  - 185px);">
    <div class="d-flex flex-wrap justify-content-between align-item-center">
      <h3>Sales Register</h3>
    </div>
    <section id="basic-tabs-components">
      <div class="row match-height">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item active">
                  <a class="nav-link active" id="home-tab-fill" data-bs-toggle="tab" href="#invoice-tab" role="tab" aria-controls="invoice-tab" aria-selected="false">Invoice Wise</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="item-tab-fill" data-bs-toggle="tab" href="#item-tab" role="tab" aria-controls="item-tab" aria-selected="false">Item Wise</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="customer-tab-fill" data-bs-toggle="tab" href="#customer-tab" role="tab" aria-controls="customer-tab" aria-selected="false">Customer Wise</a>
                </li>
              </ul>
              <hr>

              <div class="tab-content">
                <div class="tab-pane show active" id="invoice-tab" role="tabpanel" aria-labelledby="invoice-tab-fill">
                   <InvoiceTab />
                </div>
                <div class="tab-pane" id="item-tab" role="tabpanel" aria-labelledby="item-tab-fill">
                  <p>Item</p>
                </div>
                <div class="tab-pane" id="customer-tab" role="tabpanel" aria-labelledby="customer-tab-fill">
                  <p>Customer</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <hr>
    <Loader v-if="loading"/>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Loader from '@/components/atom/LoaderComponent'
import InvoiceTab from '@/components/molecule/company/inventory/product-sales-register/InvoiceTab.vue'

const loading = ref(false)

</script>
